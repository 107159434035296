.inputWrapper{
    width: 100%;
    height: auto;
    display: flex;;
    flex-wrap: wrap;
}

.inputWrapper label{
    width: 100%;
    display: block;
    font-size: 18px;
    font-weight: 500;
}

.inputWrapperCheckbox{
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}

.inputWrapper {
    margin-bottom: 16px;
}

.inputWrapper label {
    font-size: 16px;
    font-weight: normal;
    margin-bottom: 6px;
}

.inputWrapper input{
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    width: 100%;
    border: 1px solid #DADCE0;
    height: 45px;
    padding: 13px 14px;
    box-sizing: border-box;
    border-radius: 5px;
    font-size: 16px;
}

.inputWrapper input:focus{
    border: 1px solid #326dff;
}

.inputWrapperCheckbox input[type="checkbox" i] {
    width: auto;
    height: auto;
    margin-right: 7px;
}

.inputWrapper textarea{
    margin-bottom: 15px;
    margin-top: 10px;
    width: 100%;
    height: 45px;
    padding: 10px;
    font-size: 16px;
    border: 1px solid #dedede;
    padding: 13px 14px;

    box-sizing: border-box;
    border-radius: 5px;
    resize: none;
    overflow: hidden;
}

.inputWrapper input[type="file" i] {
    width: 100%;
    border: 1px solid #dedede;
    padding: 12px 10px;
    font-size: 16px;
    height: auto;
}

.inputWrapper span{
    margin-top: 3px;
    color: #f44336;
    font-size: 0.75rem;
}

.inputWrapper select{
    width: 100%;
    border: 1px solid #DADCE0;
    height: 45px;
    padding: 13px 14px;
    box-sizing: border-box;
    border-radius: 5px;
    font-size: 16px;
}
