@import url(https://fonts.googleapis.com/css?family=Roboto:300,400,500,700,900&display=swap);
body {
  margin: 0;
  font-family: 'Roboto', sans-serif;
  padding: 0;
  overflow-x: hidden;
}

#viewerBackdrop button.arrow_12iocnn-o_O-arrow__direction__right_6eoou0-o_O-arrow__size__medium_5gm619 {
    display: none;
}

#viewerBackdrop button.arrow_12iocnn-o_O-arrow__direction__left_lg4jz7-o_O-arrow__size__medium_5gm619 {
  display: none;
}
.delivery_formContainer__36kF3{
    width: 100%;
    height: 100%;
    display: flex;
    background: #fff;
    flex-wrap: wrap;
    padding-top: 15px;
}


.formControls_inputWrapper__1qPHb{
    width: 100%;
    height: auto;
    display: flex;;
    flex-wrap: wrap;
}

.formControls_inputWrapper__1qPHb label{
    width: 100%;
    display: block;
    font-size: 18px;
    font-weight: 500;
}

.formControls_inputWrapperCheckbox__2dhsC{
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}

.formControls_inputWrapper__1qPHb {
    margin-bottom: 16px;
}

.formControls_inputWrapper__1qPHb label {
    font-size: 16px;
    font-weight: normal;
    margin-bottom: 6px;
}

.formControls_inputWrapper__1qPHb input{
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    width: 100%;
    border: 1px solid #DADCE0;
    height: 45px;
    padding: 13px 14px;
    box-sizing: border-box;
    border-radius: 5px;
    font-size: 16px;
}

.formControls_inputWrapper__1qPHb input:focus{
    border: 1px solid #326dff;
}

.formControls_inputWrapperCheckbox__2dhsC input[type="checkbox" i] {
    width: auto;
    height: auto;
    margin-right: 7px;
}

.formControls_inputWrapper__1qPHb textarea{
    margin-bottom: 15px;
    margin-top: 10px;
    width: 100%;
    height: 45px;
    padding: 10px;
    font-size: 16px;
    border: 1px solid #dedede;
    padding: 13px 14px;

    box-sizing: border-box;
    border-radius: 5px;
    resize: none;
    overflow: hidden;
}

.formControls_inputWrapper__1qPHb input[type="file" i] {
    width: 100%;
    border: 1px solid #dedede;
    padding: 12px 10px;
    font-size: 16px;
    height: auto;
}

.formControls_inputWrapper__1qPHb span{
    margin-top: 3px;
    color: #f44336;
    font-size: 0.75rem;
}

.formControls_inputWrapper__1qPHb select{
    width: 100%;
    border: 1px solid #DADCE0;
    height: 45px;
    padding: 13px 14px;
    box-sizing: border-box;
    border-radius: 5px;
    font-size: 16px;
}

.preloader_container__1wbOa{
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: #fff;
    opacity: 0.8;
    z-index: 9999;
}

.preloader_container__1wbOa img{
    width: 200px;
    height: auto;
   
}
.products_modal__NvnOn{
    width: 100%;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    background: white;
    border-radius: 10px;
}

.products_wrapper__2lB7B {
    width: 100%;
    padding: 16px;
    padding-top: 24px;
    padding-bottom: 16px;
}

.products_formContainer__2bqOq{
    width: 100%;
    max-width: 100%;
    margin-top: 25px;
}

.products_title__11rqv{
    font-size: 22px;
    font-family: Roboto;
    font-weight: bold;
    line-height: 1;
    letter-spacing: 0.003em;
}

.products_formContainer__2bqOq button{
    color: white;
    width: auto;
    border: 0;
    height: 45px;
    font-size: 14px;
    min-width: 180px;
    background: #326dff;
    margin-top: 10px;
    border-radius: 3px;
    cursor: pointer;
}

.products_formContainer__2bqOq button:hover{
    background: rgba(50, 109, 255, .75);
}

.products_field__2PgxH {
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    height: 55px !important;
    overflow-y: auto !important;
}
.products_buttonWrapper__3Dlw2 {
    width: 100%;
    text-align: center;
}
.edit_container__lZPLH{
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    position: relative;
    flex-wrap: wrap;
}

.edit_categoryContainer__1exOi{
    width: calc(100% - 275px);
    height: auto;
    display: block;
    padding-top: 25px;
    padding-bottom: 20px;
    overflow: auto;
    display: flex;
    overflow-x: scroll;
    white-space: nowrap;
    position: -webkit-sticky;
    position: sticky;
    top: 74px;
    background: #fafafa;
}

.edit_navigation__3zPxd {
    width: calc(100vw - 270px);
    overflow-x: auto;
}

.edit_listCategories__2APiq {
    display: flex;
    align-items: center;
    margin: 0;
    padding: 10px 0;
}

.edit_category__17-7u{
    display: flex;
    padding: 10px 25px;
    display: flex;
    box-shadow: 0px -1px 14px rgba(0, 0, 0, 0.09);
    border-radius: 8px;
    cursor: pointer;
    margin: 0px 10px;
    font-weight: 600;
    text-decoration: none;
    text-align: center;
    white-space: nowrap;
}

.edit_activeCategory__1wRAo{
    background: #326dff;
    color: #fff;
}

.edit_productContainer__27TgP{
    width: 100%;
    height: auto;
    display: flex;
    flex-wrap: wrap;
}

.edit_product__3cX7x{
    min-width: 300px;
    max-width: 25%;
    width: 100%;
    height: auto;
    display: flex;
    flex-wrap: wrap;
}

.edit_imgProduct__3SNAB{
    width: 100%;
    height: 215px;
    display: block;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    background-color: white;
    text-align: center;

}

.edit_row__2Xuii{
    width: 100%;
    height: auto;
    display: flex;
    align-items: flex-end;
    align-content: space-between;
    flex-wrap: wrap;
    margin: 10px;
    box-shadow: 0px -1px 14px rgba(0, 0, 0, 0.09);
}

.edit_wraper__3TQEV {
    padding: 15px;
    /* display: flex; */
    flex-wrap: wrap;
    width: calc(100% - 30px);
    height: auto;
}

.edit_wraper__3TQEV .edit_item__zIOdv:nth-child(2) {
    max-height: 120px;
    height: 100%;
    overflow: hidden;
}

.edit_wraper__3TQEV select {
    width: 100%;
    height: 40px;
    border: none;
    background: #f1efef;
}

.edit_item__zIOdv {
    width: 100%;
    height: auto;
    display: flex;
    flex-wrap: wrap;
    padding-top: 10px;
}

.edit_item__zIOdv span {
    width: 100%;
    font-weight: 500;
    padding-bottom: 5px;
    font-size: 18px;
}

.edit_description__mWoZz {
    height: 110px;
    max-width: 250px;
    width: 100%;
    overflow: hidden;
}

.edit_button__1inTM {
    color: white;
    width: 100%;
    border: 0;
    height: 45px;
    line-height: 45px;
    font-size: 14px;
    min-width: 180px;
    text-align: center;
    font-size: 16px;
    background: #326dff;
    margin-top: 10px;
    border-radius: 3px;
    cursor: pointer;
}

.edit_info__SQVej{
    width: 100%;
    height: auto;
    display: flex;
    flex-wrap: wrap;
    padding: 10px 0px;
}

.edit_languageButton__3nseN{
    position: fixed;
    right: 15px;
    bottom: 15px;
    width: 48px;
    height: 48px;
    border-radius: 50%;
    background: #326dff;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    font-weight: 500;
    cursor: pointer;
    box-shadow: 0px -1px 14px rgba(0, 0, 0, 0.09);
    text-transform: uppercase;
    font-size: 17px;
    z-index: 9999;
}

.edit_modalLanguage__3Qsxj{
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0px;
    left: 0px;
    bottom: 0px;
    right: 0px;
    background: #ffffffb3;
    z-index: 9999;
}

.edit_languageContainer__3Scm-{
    max-width: 350px;
    width: 100%;
    height: auto;
    display: flex;
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
}

.edit_language__38-CU{
    width: 100%;
    height: 100%;
    margin: 15px;
    background: #fff;
    box-shadow: -2px 1px 47px -21px rgba(0,0,0,0.76);
    padding: 25px 30px;
    display: flex;
    flex-wrap: wrap;
    border-radius: 5px;
    position: relative;
}

.edit_language__38-CU span{
    width: 100%;
    height: auto;
    padding: 10px;
    font-size: 18px;
    font-weight: 600;
    cursor: pointer;
}

.edit_title__3Vctk{
    font-size: 22px;
    font-weight: 600;
    text-align: center;
    width: 100%;
    padding: 10px 0px;
}

.edit_activeLanguage__3sJ5o{
    color: #326dff;
}

.edit_close__1FrDy{
    width: 30px;
    height: 30px;
    position: absolute;
    right: 15px;
    top: 15px;
    background: #326dff;
    border-radius: 50%;
    cursor: pointer;
    background-image: url(/static/media/close.2e13afc6.svg);
    background-position: center;
    background-size: 12px;
    background-repeat: no-repeat;
}

.edit_buttonWrappers__2RCcg {
   width: 100%;
}

.edit_titleWrap__2lP8c {
    width: calc(100% - 0px);
    display: flex;
    justify-content: space-between;
}

.edit_titleText__TsTWI {
    max-height: 110px;
    height: 100%;
    max-width: 250px;
    width: 100%;
    overflow: hidden;
}

@media (max-width: 1024px) {
    .edit_product__3cX7x{
        max-width: 33.33%;
    }
 }

 @media (max-width: 959px) {
    .edit_navigation__3zPxd {
        width: calc(100vw - 32px);
    }
    .edit_categoryContainer__1exOi{
        width: calc(100% - 30px);
    }
    .edit_productContainer__27TgP {
        width: calc(100% - 30px);
    }
 }

 @media (max-width: 599px) {
    .edit_product__3cX7x {
        margin: 0 auto;
    }
 }

@media (max-width: 540px) {
    .edit_product__3cX7x{
        max-width: 100%;
    }
 }

 
.products_modal__28ImH{
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0px;
    left: 0px;
    bottom: 0px;
    right: 0px;
    background: #ffffffb3;
    z-index: 99;
}

.products_form__31frg{
    max-width: 400px;
    width: 100%;
    height: auto;
    display: flex;
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
}

.products_formContainer__2bF74{
    width: 100%;
    height: 100%;
    margin: 15px;
    background: #fff;
    box-shadow: -2px 1px 47px -21px rgba(0,0,0,0.76);
    padding: 25px 30px;
    display: flex;
    flex-wrap: wrap;
    border-radius: 5px;
    position: relative;
}

.products_formContainer__2bF74 button {
    width: 100%;
    max-width: 250px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #326dff;
    margin: auto;
    margin-top: 10px;
    border-radius: 5px;
    font-size: 16px;
    color: #fff;
    font-weight: 500;
    cursor: pointer;
    border: none;
}

.products_formContainer__2bF74 button:hover {
    background: rgba(50, 109, 255, .75);
}

.products_title__33vGr{
    font-size: 22px;
    font-weight: 600;
    text-align: center;
    width: 100%;
    padding: 10px 0px;
}

.products_close__3Svbq{
    width: 35px;
    height: 35px;
    position: absolute;
    right: 15px;
    top: 15px;
    background: #326dff;
    border-radius: 50%;
    cursor: pointer;
    background-image: url(/static/media/close.2e13afc6.svg);
    background-position: center;
    background-size: 15px;
    background-repeat: no-repeat;
}

.products_citysLabel__jOUme {
    position: relative;
}

.products_citysLabel__jOUme::before {
    content: "";
    position: absolute;
    top: 11px;
    right: -11px;
    width: 10px;
    height: 20px;
    background-image: url(/static/media/more.5d14e7e5.svg);
    background-repeat: no-repeat;
    background-position: center;
    opacity: .5s;
}

.products_citysLabel__jOUme > .products_MuiCheckbox-colorSecondary__3NMgT.products_Mui-checked__cyffn{ 
    color: blue;
}
.products_modal__3UoN-{
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0px;
    left: 0px;
    bottom: 0px;
    right: 0px;
    background: #ffffffb3;
    z-index: 99;
}

.products_form__pgYtW{
    width: 100%;
    height: auto;
    display: flex;
}

.products_formContainer__3paY2 {
    width: 100%;
    height: 100%;
    margin: 15px;
    background: #fff;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    padding: 25px 30px;
    display: flex;
    flex-wrap: wrap;
    border-radius: 5px;
    position: relative;
    display: flex;
    flex-direction: column;
}

.products_formContainer__3paY2 button {
    width: 100%;
    max-width: 250px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #326dff;
    margin: auto;
    margin-top: 10px;
    border-radius: 5px;
    font-size: 16px;
    color: #fff;
    font-weight: 500;
    cursor: pointer;
    border: none;
}

.products_formContainer__3paY2 button:hover {
    background: rgba(50, 109, 255, .75);
}

.products_title__3LAbh{
    font-size: 22px;
    font-weight: 600;
    text-align: center;
    width: 100%;
    padding: 10px 0px;
}

.products_close__1hl72{
    width: 35px;
    height: 35px;
    position: absolute;
    right: 15px;
    top: 15px;
    background: #326dff;
    border-radius: 50%;
    cursor: pointer;
    background-image: url(/static/media/close.2e13afc6.svg);
    background-position: center;
    background-size: 15px;
    background-repeat: no-repeat;
}

/* .citiesLabel {
    position: relative;
    margin-right: 20px;
} */

/* .citiesLabel::before {
    content: "";
    position: absolute;
    top: 11px;
    right: -11px;
    width: 10px;
    height: 20px;
    background-image: url(../../../../assets/img/more.svg);
    background-repeat: no-repeat;
    background-position: center;
    opacity: .5s;
} */

.products_citiesLabel__2lbBL > .products_MuiCheckbox-colorSecondary__25yJU.products_Mui-checked__1A2B2{ 
    color: blue;
}

@media screen and (max-width: 650px) {
    .products_formContainer__3paY2 {
        margin: 0;
    }
  }
  
.login_container__1Jy3i{
    width: 100%;
    height: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}

.login_containerForm__1ZKC0{
    max-width: 900px;
    width: 100%;
    height: auto;
    display: flex;
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    flex-wrap: wrap;
}
.login_row__GRsET{
    width: 100%;
    height: 100%;
    display: flex;
    flex-wrap: wrap;
    box-shadow: -2px 1px 47px -21px rgba(0,0,0,0.76);
    background: #fff;
    margin: 15px;
    min-height: 500px;
}
.login_wrapper__1lrRU{
    width: 50%;
    height: auto;
    display: flex;
}

.login_bgContainer__2r-5c{
    width: 100%;
    height: 100%;
    background: url(/static/media/login_bg.7be94d56.png);
    background-size: cover;
    background-position: center;
}

.login_formContainer__3ooum{
    width: 50%;
    min-width: 380px;
    height: 100%;
    background: #dedede;
}

.login_loginForm__Ds1kh{
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.login_loginForm__Ds1kh form{
    width: 100%;
    padding: 15px 45px;
}

.login_title__2akW0{
    font-size: 28px;
    color: #326dff;
    font-weight: 700;
    padding-bottom: 5px;
}

@media (max-width: 768px) { 
    .login_wrapper__1lrRU{
        width: 100%;
        height: 350px;
    }
    .login_wrapper__1lrRU:nth-child(1){
       height: 250px;
    }
 }
.login_loginForm__2icKz{
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.login_loginForm__2icKz form{
    width: 100%;
    padding: 15px 45px;
}

.login_title__1TUJi{
    font-size: 28px;
    color: #326dff;
    font-weight: 700;
    padding-bottom: 5px;
}

.login_buttonsWrap__27y_S {
    display: flex;
    justify-content: center;
}

.login_buttonsWrap__27y_S button {
    min-width: 160px;
    margin: 0 5px;
}

.login_buttonsWrap__27y_S button:last-child {
    background: transparent;
    color: gray;
}

.login_buttonsWrap__27y_S button:last-child:hover {
    color: #666;
}

@media (max-width: 768px) { 
    .login_wrapper__3mJKm{
        width: 100%;
        height: 350px;
    }
    .login_wrapper__3mJKm:nth-child(1){
       height: 250px;
    }
 }
.login_loginForm__1W0as{
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.login_loginForm__1W0as form{
    width: 100%;
    padding: 15px 45px;
}

.login_title__3Tuta{
    font-size: 28px;
    color: #326dff;
    font-weight: 700;
    padding-bottom: 5px;
}

@media (max-width: 768px) { 
    .login_wrapper__3uphA{
        width: 100%;
        height: 350px;
    }
    .login_wrapper__3uphA:nth-child(1){
       height: 250px;
    }
 }
